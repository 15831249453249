import React from "react"
import { Container } from 'reactstrap';

import styles from '../styles/netPriceCalculator.module.css'

const NetPriceCalculator = () => {
    return (
        <section className={styles.netPriceCalcContainer}>
            <iframe src="../../index-net-price-calc.html"/>
        </section>
    )
}

export default NetPriceCalculator