import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import NetPriceCalculator from "../components/netPriceCalculator";


const NetCalculater = () => (
  <Layout>
    <SEO title="Net Price Calculators" />
    <br />
    <NetPriceCalculator />
  </Layout>
)

export default NetCalculater